@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "datatables.net-bs5/css/dataTables.bootstrap5.css";

[x-cloak] {
    display: none !important;
}

tr[data-uuid],
tr[data-uuid] td {
    transition: background-color 1s;
}

.shiki {
    white-space: pre-wrap;
}

/* https://github.com/shikijs/shiki/issues/3#issuecomment-830564854 */
.shiki code {
    counter-reset: step;
    counter-increment: step 0;
}

.shiki code .line::before {
    content: counter(step);
    counter-increment: step;
    width: 1rem;
    margin-right: 1.5rem;
    display: inline-block;
    text-align: right;
    color: rgba(115, 138, 148, 0.4);
    white-space: nowrap;
}
